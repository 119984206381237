<template>
    <div>
        <div class="wrapper">
            <div class="leftArrow">
                <img src="../../img/arrow-left.png" @click="arrowClick1('left')" alt="">
            </div>
            <el-carousel id="el-carousel1" :interval="5000" arrow="never" ref="slideCarousel1">
                <el-carousel-item v-for="(item,index) in bannerImgs1" :key="index">
                    <img :src="item.url" class="banner-img" />
                    <span v-for="(tItem,tIndex) in bannerFloatText" :key="tIndex">
                        <i class="twins-park-float-text" v-show="index==tIndex">{{tItem.name}}</i>
                    </span>
                </el-carousel-item>
            </el-carousel>
            <div class="rightArrow">
                <img src="../../img/arrow-right.png" @click="arrowClick1('right')" alt="">
            </div>
        </div>
        <div class="home-bg1">
            <div class="home-bg1-content">
                <div class="tip-text">
                    <p class="tip-text-title">探索智能时代的</p>
                    <p class="tip-text-con">自来水厂</p>
                    <p class="tip-text-btn" @click="productDetails">了解详情</p>
                </div>
                <div class="right-banner">
                    <div class="leftArrow">
                        <img src="../../img/arrow-left.png" @click="arrowClick2('left')" alt="">
                    </div>
                    <el-carousel id="el-carousel2" class="carousel2" :interval="5000" arrow="never" ref="slideCarousel2">
                        <el-carousel-item v-for="(item,index) in bannerImgs2" :key="index">
                            <img :src="item.url" class="banner-img" />
                        </el-carousel-item>
                    </el-carousel>
                    <div class="rightArrow">
                        <img src="../../img/arrow-right.png" @click="arrowClick2('right')" alt="">
                    </div>
                    <div class="right-banner-before1"></div>
                    <div class="right-banner-before2"></div>
                </div>
            </div>
        </div>
        <div class="home-bg2">
            <div class="home-bg2-content">
                <div class="middle-banner">
                    <div class="leftArrow">
                        <img src="../../img/arrow-left.png" @click="arrowClick3('left')" alt="">
                    </div>
                    <el-carousel id="el-carousel3" class="carousel3" indicator-position="none" :interval="5000" type="card" arrow="never" ref="slideCarousel3" @change="changeSlide3">
                        <el-carousel-item class="carousel3-item" v-for="(item,index) in bannerImgs3" :key="index" :name="'a'+index">
                            <img :src="item.url" class="banner-img" @click="bannerDetail3(item.name)"/>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="rightArrow">
                        <img src="../../img/arrow-right.png" @click="arrowClick3('right')" alt="">
                    </div>
                </div>
                <div class="middle-banner-text">
                    <div class="m-b-txt" v-for="(item,index) in bannerTabText" :key="index">
                        <span :class="currentIndex=='a'+index?'acitve-text':''" @click="toggleBanner('a'+index)">{{item.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-footer">
            <div class="footer-con">
                <img class="con-logo" src="../../img/logo-white.png" alt="">
                <div class="con-list">
                    <span @click="productDetails">产品详情</span>
                    <span @click="services">技术与服务</span>
                    <span @click="about">关于迪倍特</span>
                    <span @click="contactUs">联系我们</span>
                </div>
                <div class="con-icon">
                    <div class="icon-wrapper">
                        <img src="../../img/wechat.png" alt="">
                        <!-- <div class="icon-qrcode">二维码</div> -->
                    </div>
                    <div class="icon-wrapper">
                        <img class="icon-phone-img" src="../../img/phone.png" alt="">
                        <div class="icon-phone-con">
                            <span class="phone-title">联系方式</span>
                            <span class="phone-con">400-029-2281</span>
                            <p class="icon-phone-arrow"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            bannerImgs1: [
                { 
                    name: '智慧园区',
                    url:require("@/img/twins-park.jpg")
                },
                { 
                    name: '智慧水厂',
                    url:require("@/img/twins-park2.jpg")
                },
                { 
                    name: '智慧城市',
                    url:require("@/img/twins-city.jpg")
                },
            ],
            bannerFloatText: [
                {name:'智慧园区'},
                {name:'智慧水厂'},
                {name:'智慧城市'},
            ],
            bannerImgs2: [
                { 
                    name: '智慧园区',
                    url:require("@/img/twins-park.jpg")
                },
                { 
                    name: '智慧水厂',
                    url:require("@/img/twins-park2.jpg")
                },
                { 
                    name: '智慧城市',
                    url:require("@/img/twins-city.jpg")
                },
            ],
            bannerImgs3: [
                { 
                    name: '运维系统',
                    url:require("@/img/operation-home.jpg")
                },
                { 
                    name: '数字孪生',
                    url:require("@/img/twins-city.jpg")
                },
                { 
                    name: '组态模块',
                    url:require("@/img/configuration.jpg")
                },
            ],
            bannerTabText: [
                {name:'运维系统'},
                {name:'数字孪生'},
                {name:'组态模块'},
            ],
            bannerHeight1: 990,
            screenWidth1: 1920,

            bannerHeight2: 600,
            screenWidth2: 1920,

            bannerHeight3: 500,
            screenWidth3: 1920,
            currentIndex: 'a'+0,
        }
    },
    methods: {
        setSize1: function() {
            var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.screenWidth1 = width;
            this.screenWidth2 = width;
            this.screenWidth3 = width;
            //图片                高 / 宽  500 / 1920
            this.bannerHeight1 = 990 / 1920 * this.screenWidth1 - 50
            this.bannerHeight2 = 600 / 1920 * this.screenWidth2 - 50
            this.bannerHeight3 = 500 / 1920 * this.screenWidth3 - 50
            document.getElementById('el-carousel1').style.height = this.bannerHeight1 + 'px';
            document.getElementById('el-carousel2').style.height = this.bannerHeight2 + 'px';
            document.getElementById('el-carousel3').style.height = this.bannerHeight3 + 'px';
        },
        setSize: function() {
            this.bannerHeight1 = 990 / 1920 * this.screenWidth1 - 50
            this.bannerHeight2 = 600 / 1920 * this.screenWidth2 - 50
            this.bannerHeight3 = 500 / 1920 * this.screenWidth3 - 50
            document.getElementById('el-carousel1').style.height = this.bannerHeight1 + 'px';
            document.getElementById('el-carousel2').style.height = this.bannerHeight2 + 'px';
            document.getElementById('el-carousel3').style.height = this.bannerHeight3 + 'px';
        },
        arrowClick1(val) {
            if(val=='right') {
                this.$refs.slideCarousel1.next()
            }else {
                this.$refs.slideCarousel1.prev()
            }
        },
        arrowClick2(val) {
            if(val=='right') {
                this.$refs.slideCarousel2.next()
            }else {
                this.$refs.slideCarousel2.prev()
            }
        },
        arrowClick3(val) {
            if(val=='right') {
                this.$refs.slideCarousel3.next()
            }else {
                this.$refs.slideCarousel3.prev()
            }
        },
        toggleBanner(index) {
            console.log(index)
            this.currentIndex = index
            this.$refs.slideCarousel3.setActiveItem(index)
        },
        changeSlide3(index) {
            this.currentIndex = 'a' + index
        },
        bannerDetail3(name) {
            let router = null
            let currentTabs = null
            if(name=='运维系统') {
                router = 'product'
                currentTabs = 0
            }
            if(name=='数字孪生') {
                router = 'product'
                currentTabs = 1
            }
            if(name=='组态模块') {
                router = 'product'
                currentTabs = 2
            }
            this.$router.push({ name: router, params: { currentTabs: currentTabs } })
        },
        productDetails() {
            this.$router.push({ name: 'product' })
        },
        services() {
            this.$router.push({ name: 'services' })
        },
        about() {
            this.$router.push({ name: 'about' })
        },
        contactUs() {
            this.$router.push({ name: 'contactUs' })
        }
    },
    mounted() {
        this.setSize1();
        const that = this;
        //监听浏览器窗口大小改变
        window.addEventListener('resize', function() {
            var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            that.screenWidth1 = width;
            that.screenWidth2 = width;
            that.screenWidth3 = width;
            that.setSize();
        }, false);
    }
}
</script>
<style lang="less" scoped>
// web端样式
.wrapper {
    margin-top: -90px;
    position: relative;
}
.banner-img {
    width: 100%;
}
.twins-park-float-text {
    display:block;
    position:absolute;
    bottom: 15%;
    right: 15%;
    color: #FFFFFF;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 10px;
}
.leftArrow {
    position: absolute;
    width: 44px;
    height: 44px;
    top: 50%;
    left: 3%;
    z-index: 990;
    background: #0048c0;
    border-radius: 50%;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
    }
}
.leftArrow:hover {
    opacity: .7;
}
.rightArrow {
    position: absolute;
    width: 44px;
    height: 44px;
    top: 50%;
    right: 3%;
    z-index: 990;
    background: #0048c0;
    border-radius: 50%;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
    }
}
.rightArrow:hover {
    opacity: .7;
}
.home-bg1 {
    width: 100%;
    height: 100vh;
    background-image: url('../../img/bg1.png');
    background-size: 100% 100%;
    position: relative;
}
.home-bg1-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 0 50px;
}
.tip-text {
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    .tip-text-title {
        font-size: 20px;
        height: 0px;
    }
    .tip-text-con {
        font-size: 40px;
        height: 30px;
    }
    .tip-text-btn {
        width: 160px;
        height: 50px;
        border: 2px solid #FFFFFF;
        border-radius: 15px;
        text-align: center;
        line-height: 50px;
        font-size: 22px;
        transition: all .3s ease;
    }
    .tip-text-btn:hover {
        background: #FFFFFF;
        color: #0048c0;
        cursor: pointer;
    }
}
.right-banner {
    width: 1200px;
    margin-left: 10%;
    position: relative;
    background: #020628;
    .right-banner-before1 {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -10px;
        left: -10px;
        z-index: 1;
        border: 2px solid #FFFFFF;
        background: #020628;
    }
    .right-banner-before2 {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: 0;
        border: 2px solid #FFFFFF;
        background: #020628;
    }
    .leftArrow {
        top: 45%;
    }
    .rightArrow {
        top: 45%;
    }
}

.home-bg2 {
    width: 100%;
    height: 100vh;
    background-image: url('../../img/bg2.png');
    background-size: 100% 100%;
    position: relative;
}
.home-bg2-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.middle-banner {
    width: 100%;
    margin-bottom: 100px;
    position: relative;
    .leftArrow {
        top: 45%;
    }
    .rightArrow {
        top: 45%;
    }
}
.middle-banner-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .m-b-txt {
        flex: 1;
        text-align: center;
        span {
            display: inline-block;
            color: #FFFFFF;
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 5px;
            cursor: pointer;
            border-bottom: 4px solid transparent;
        }
        .acitve-text {
            border-bottom: 4px solid #FFFFFF;
        }
        span:hover {
            color: #CCCCCC;
        }
    }   
}
.home-footer {
  width: 100%;
  height: 290px;
  position: relative;
  background: #000000;
  box-sizing: border-box;
  .footer-con {
    height: 70px;
    border-bottom: 3px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto 100px;
    .con-logo {
      height: 180px;
    }
    .con-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #FFFFFF;
        font-size: 24px;
        width: 20%;
        display: block;
        text-align: center;
        cursor: pointer;
      }
      span:hover {
        color: #CCCCCC;
      }
    }
    .con-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-wrapper {
        position: relative;
        .icon-phone-con {
            width: 150px;
            position: absolute;
            top: -80px;
            right: 0px;
            background: #FFFFFF;
            border-radius: 10px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px 15px;
            display: none;
            .phone-title {
                font-size: 14px;
                color: #888888;
                padding-bottom: 5px;
                display: inline-block;
            }
            .phone-con {
                font-size: 24px;
                color: #000000;
                display: inline-block;
            }
            .icon-phone-arrow {
                position: absolute;
                bottom: -35px;
                right: 30px;
                border-left: 10px solid transparent;
                border-top: 10px solid #FFFFFF;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
            }
        }
      }
      .icon-wrapper:hover .icon-phone-con {
        display: block;
      }
      img {
        height: 60px;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
}
.carousel2 {
    border: 2px solid #FFFFFF;
    z-index: 2;
}
.carousel3 {
    .carousel3-item {
        height:98%;
        border: 2px solid #FFFFFF;
    }   
}

// 移动端样式
@media screen and (max-width:650px) {
    .wrapper {
        margin-top: 0;
    }
    .twins-park-float-text {
        font-size: 14px;
        letter-spacing: 3px;
    }
    .home-bg1 {
        height: 450px;
    }
    .home-bg1-content {
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .right-banner {
        width: 100%;
        margin: 0;
        background: none;
        .leftArrow {
            top: 40%;
        }
        .rightArrow {
            top: 40%;
        }
    }
    .tip-text {
        margin-bottom: 30px;
    }
    .tip-text-title {
        font-size: 14px !important;
        height: 0px;
    }
    .tip-text-con {
        font-size: 24px !important;
        height: 30px;
    }
    .tip-text-btn {
        width: 120px !important;
        height: 30px !important;
        border: 2px solid #FFFFFF;
        border-radius: 15px;
        text-align: center;
        line-height: 30px !important;
        font-size: 14px !important;
        transition: all .3s ease;
    }
    .home-bg2 {
        height: 200px;
    }
    .m-b-txt {
        span {
            font-size: 14px !important;
        }
    }
    .home-footer {
        height: 200px !important;
    }
    .footer-con {
        flex-direction: column;
        border-bottom: 0 !important;
        margin: 0 !important;
    }
    .con-logo {
        height: 100px !important;
    }
    .con-list {
        span {
            width: 100% !important;
            display: inline-block;
            font-size: 14px !important;
        }
    }
    .con-icon {
        margin-top: 20px;
        img {
            height: 40px !important;
            margin: 0 5px !important;
        }
    }
    .middle-banner {
        margin-bottom: 20px;
        .leftArrow {
            top: 30%;
        }
        .rightArrow {
            top: 30%;
        }
    }
    .leftArrow { top: 40%; }
    .rightArrow { top: 40%; }
    .carousel2 {
        border: 0;
    }
    .carousel3 {
        .carousel3-item {
            height:100%;
            border: 0;
        }   
    }
    .right-banner-before1 {
        display: none;
    }
    .right-banner-before2 {
        display: none;
    }
}
</style>
<style>
/* web端样式 */
.el-carousel__container {
    height: 100% !important;
}
.el-carousel__indicator--horizontal {
    padding: 12px 10px;
}
.el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #FFFFFF;
}
.el-carousel__indicator.is-active button {
    background: #5BEBFF;
}
.el-carousel__indicators {
    bottom: 3%;
}

/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-carousel {
        height: 200px !important;
    }
    .el-carousel--card {
        height: 110px !important;
    }
    /* .el-carousel__item--card {
        width: 70%;
    } */
}
</style>